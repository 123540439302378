<template>
  <div class="d-flex align-center">
    <v-icon
      :disabled="isDashboard"
      small
      color="techBlue"
      @click="onRootIconClick"
    >
      {{ rootIcon }}
    </v-icon>
    <v-icon v-if="!isDashboard" color="techBlueDark" small class="mx-1">
      mdi-chevron-right
    </v-icon>
    <div v-if="!isDashboard" class="text-body-2 text--secondary">
      {{ label }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheBreadcrumb",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      isImpersonateEnterprise: "user/isImpersonateEnterprise",
    }),
    rootIcon() {
      return this.isImpersonateEnterprise ? "mdi-domain" : "mdi-home";
    },
    // disableRootIcon() {
    //   return this.isImpersonateEnterprise || this.isDashboard;
    // },
    isDashboard() {
      return this.$route.name === "dashboard";
    },
  },
  methods: {
    onRootIconClick() {
      if (!this.$route.path.endsWith("esg/eligibility-tests")) {
        if (this.$route.path.startsWith("/esg/")) {
          this.$router.push({ name: "eligibility-tests" });
        } else {
          const destination = this.isImpersonateEnterprise
            ? "balances"
            : "dashboard";
          if (!this.$route.path.endsWith("/" + destination)) {
            this.$router.push({ name: destination });
          }
        }
      }
    },
  },
};
</script>
